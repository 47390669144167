import React, { useEffect, createContext } from 'react'
import { useTranslation } from 'react-i18next'

export type ErrorKey = 'sa.errors.load_error' | 'sa.errors.upload_error'

export const FloatingErrorContext = createContext<(error: ErrorKey) => void>(() => {})

export const FloatingError = ({ currentError }: { currentError?: ErrorKey | null }) => {
  const { t } = useTranslation()
  useEffect(() => {
    document.body.style.overflow = currentError ? 'hidden' : 'auto'
  }, [currentError])

  if (!currentError) return null

  return (
    <>
      <div id="disabled-background" tabIndex={-1}></div>
      <div id="floating-error">
        <div className="error-symbol">&#x26A0;</div>
        <div className="message localized-error" dangerouslySetInnerHTML={{ __html: t(currentError) }} />
        <button id="logout-on-error" onClick={() => window.location.assign('/kurko-api/user/logout')}>
          {t('sa.logout')}
        </button>
        <button id="reload-page" onClick={() => location.reload()}>
          {t('sa.errors.reload_page')}
        </button>
      </div>
    </>
  )
}
