import React, { useContext, useState } from 'react'
import axios from 'axios'
import { UserDataContext } from './app'
import { FloatingErrorContext } from './common/floating-error'
import { EulaRole } from './common/types'
import { useTranslation } from 'react-i18next'

export const EulaContainer = ({ close, role }: { close: () => void; role: EulaRole }) => {
  const { user } = useContext(UserDataContext)
  const setCurrentError = useContext(FloatingErrorContext)
  function accept(eulaRole: string) {
    axios
      .post('/kurko-api/user/acceptEula', { role: eulaRole })
      .then(() => {
        user?.acceptedEulaRoles.push(role)
        close()
        return
      })
      .catch(() => setCurrentError('sa.errors.load_error'))
  }
  return user?.acceptedEulaRoles.includes(role) || user?.details.impersonation ? (
    <CurrentEula closeEula={close} role={role} />
  ) : (
    <Eula acceptEula={accept} role={role} />
  )
}

const Eula = ({ acceptEula, role }: { acceptEula: (eulaRole: EulaRole) => void; role: EulaRole }) => {
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation()
  return (
    <div id="eula-container">
      <div id="eula-dialog-base">
        <div dangerouslySetInnerHTML={{ __html: t(`sa.eula.${role}.terms`) }} />
        <div id="eula-agreement-container">
          <div className="eula-checkbox-container">
            <input type="checkbox" id="eula-agreement-checkbox" checked={checked} onChange={() => {}} />
            <label htmlFor="eula-agreement-checkbox" onClick={() => setChecked(checked => !checked)}>
              <span className="eula-custom-checkbox" />
              <div dangerouslySetInnerHTML={{ __html: t(`sa.eula.${role}.agreement`) }} />
            </label>
          </div>
          <button id="eula-agree-button" disabled={!checked} onClick={() => acceptEula(role)}>
            {t(`sa.eula.${role}.agree`)}
          </button>
        </div>
      </div>
    </div>
  )
}

const CurrentEula = ({ closeEula, role }: { closeEula: () => void; role: EulaRole }) => {
  const { t } = useTranslation()

  return (
    <div id="eula-container">
      <div id="eula-dialog-base">
        <div dangerouslySetInnerHTML={{ __html: t(`sa.eula.${role}.terms`) }} />
        <div id="eula-display-container">
          <div dangerouslySetInnerHTML={{ __html: t('sa.eula.updated') }} />
          <button id="eula-close-dialog-button" onClick={closeEula}>
            {t('sa.eula.return')}
          </button>
        </div>
      </div>
    </div>
  )
}
