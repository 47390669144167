import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate, NavLink } from 'react-router-dom'
import { ExaminationPhaseTable } from '../examination-phase/examination-phase-table'
import { ResultListContainer } from '../results/result-list'
import { Teachers } from '../manage-teachers'
import { Registration } from '../registration/registration'
import { ExamEvents } from '../exam-events/exam-events'
import { Censoring } from '../held-exams/censoring'
import { UnifiedRole } from './types'
import { EulaContainer } from '../eula'
import Cookies from 'js-cookie'
import { RoleDisplay } from '../role-display'
import { NoAccess } from '../no-access'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { UserDataContext } from '../app'
import { ErrorKey, FloatingError, FloatingErrorContext } from './floating-error'
import { Pregrading } from '@digabi/grading-ui/lib/held-exams/pregrading'
import { pregradingExamUrls } from '../grading/pregrading-exam-urls'
import { Role, RoleType, SchoolPersonnelRole, Language } from '@digabi/grading-ui/lib/common/types'
import { useTranslation } from 'react-i18next'
import { Credentials } from '../credentials/credentials'

const ROLE_COOKIE_NAME = 'selected-role'
const ALLOWED_ROLES = ['CENSOR', 'PRINCIPAL', 'ASSISTANT', 'STUDENTCOUNSELLOR', 'GRADING_TEACHER']

const eulaRoleToRoleTypeMapping = {
  GRADING_TEACHER: 'teacher',
  PRINCIPAL: 'principal',
  CENSOR: 'censor',
  ASSISTANT: '', // assistants and student counsellors don't have eula
  STUDENTCOUNSELLOR: ''
} as const

export function TabManager() {
  const { t, i18n } = useTranslation()
  const lang = i18n.language as Language
  const [error, setError] = useState<ErrorKey | null>(null)
  const { roles, user } = useContext(UserDataContext)
  const unifiedRoles = roles ? getUnifiedRoles(roles) : []
  const [activeRole, setActiveRole] = useState(getInitialRole(unifiedRoles))
  const eulaRole = eulaRoleToRoleTypeMapping[activeRole.roleType]
  const hasEula = eulaRole !== ''
  const shouldShowEula = hasEula && !user?.acceptedEulaRoles.includes(eulaRole) && !user?.details.impersonation

  const [showEula, setShowEula] = useState(shouldShowEula)
  useEffect(() => setShowEula(shouldShowEula), [activeRole])

  const schoolRole = roles?.find(
    role => role.roleType === 'GRADING_TEACHER' && role.schoolId === activeRole.scopeId
  ) as SchoolPersonnelRole
  const { defaultTab, tabs } = getTabsForRole(activeRole, schoolRole?.allowedExams ?? [], lang)
  const prefix = activeRole.roleType === 'CENSOR' ? 'censor' : 'school'
  return (
    <FloatingErrorContext.Provider value={setError}>
      <FloatingError currentError={error} />
      <div className="role-information">
        <RoleDisplay activeRole={activeRole} unifiedRoles={unifiedRoles} setActiveRole={setActiveRole} />
        {!showEula && hasEula && (
          <button id="eula-show-current-button" onClick={() => setShowEula(true)}>
            {t('sa.eula.button')}
          </button>
        )}
        {activeRole.roleType === 'CENSOR' && !showEula && (
          <a className="censor-payments-link" href="/detailed-censor-payments">
            <i className="fa fa-download"></i> <span>{t('sa.censor.download_payments')}</span>
          </a>
        )}
      </div>
      <SwitchTransition>
        <CSSTransition key={showEula ? 'eula' : 'page'} classNames="toggle" timeout={200}>
          {showEula ? (
            hasEula ? (
              <EulaContainer close={() => setShowEula(false)} role={eulaRole} />
            ) : (
              <></>
            )
          ) : (
            <div id="role-based-content">
              <BrowserRouter>
                <div id={`${activeRole.roleType.toLowerCase()}-container`}>
                  {tabs.length > 1 && (
                    <div id="tab-bar">
                      {tabs.map(({ path, textKey }) => (
                        <NavLink key={path} id={`${path}-link`} to={`/${prefix}/${path}`} role={'tab'}>
                          {t(`${textKey}`)}
                        </NavLink>
                      ))}
                    </div>
                  )}
                  <Routes>
                    {tabs.map(({ path, element }) => (
                      <Route key={path} path={`/${prefix}/${path}`} element={element} />
                    ))}
                    <Route path="*" element={<Navigate replace to={`/${prefix}/${defaultTab}`} />} />
                  </Routes>
                </div>
              </BrowserRouter>
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </FloatingErrorContext.Provider>
  )
}

function getInitialRole(unifiedRoles: UnifiedRole[]) {
  const roleString = Cookies.get(ROLE_COOKIE_NAME)
  const roleInCookie = JSON.parse(roleString || '{}') as UnifiedRole
  const roleMatchingUserAndCookie =
    roleInCookie &&
    unifiedRoles.find(role => roleInCookie.scopeId === role.scopeId && role.roleType === roleInCookie.roleType)
  return roleMatchingUserAndCookie || unifiedRoles[0]
}

function unifyRole(role: Role) {
  return role.roleType !== 'CENSOR'
    ? { roleType: role.roleType, scopeName: role.schoolName, scopeId: role.schoolId }
    : { roleType: role.roleType, scopeName: role.divisionName, scopeId: role.divisionId }
}

function getUnifiedRoles(roles: Role[]) {
  return roles
    .filter(r => ALLOWED_ROLES.includes(r.roleType))
    .map(unifyRole)
    .sort((a, b) => ALLOWED_ROLES.indexOf(a.roleType) - ALLOWED_ROLES.indexOf(b.roleType))
}

function getTabsForRole(activeRole: UnifiedRole, allowedExams: string[], lang: Language) {
  const exams = { path: 'exams', element: <ExamEvents {...activeRole} /> } as const
  const preGrading = {
    path: 'grading',
    element: (
      <Pregrading
        pregradingExamUrls={pregradingExamUrls}
        scopeId={activeRole.scopeId}
        roleType={activeRole.roleType}
        allowedExams={allowedExams}
        lang={lang}
        examReviewRequired={false}
        examsDeletable={false}
      />
    )
  } as const
  const censoring = { path: 'grading', element: <Censoring {...activeRole} /> } as const
  const teachers = { path: 'teachers', element: <Teachers {...activeRole} /> } as const
  const registration = { path: 'registration', element: <Registration {...activeRole} /> } as const
  const results = { path: 'results', element: <ResultListContainer {...activeRole} /> } as const
  const examinationPhase = {
    path: 'examination-phase',
    element: <ExaminationPhaseTable {...activeRole} />
  } as const
  const credentials = { path: 'credentials', element: <Credentials {...activeRole} /> } as const

  const TABS = {
    GRADING_TEACHER: { defaultTab: 'grading', tabs: [preGrading, results] },
    ASSISTANT: { defaultTab: 'exams', tabs: [registration, examinationPhase, exams] },
    PRINCIPAL: {
      defaultTab: 'exams',
      tabs: [registration, examinationPhase, exams, preGrading, results, teachers, credentials]
    },
    STUDENTCOUNSELLOR: { defaultTab: 'examination-phase', tabs: [registration, examinationPhase, results] },
    CENSOR: { defaultTab: 'grading', tabs: [censoring] }
  }

  const { defaultTab, tabs } = TABS[activeRole.roleType] || {
    defaultTab: '',
    tabs: [{ path: '', element: <NoAccess /> }]
  }

  type TabPath = (typeof tabs)[number]['path']
  function getTabKey(path: TabPath, roleType: RoleType) {
    if (path === 'exams' && roleType === 'ASSISTANT') {
      return 'sa.exams-material_tab'
    }
    return `sa.${path}_tab` as const
  }
  type TabWithKey = (typeof tabs)[number] & { textKey: ReturnType<typeof getTabKey> }
  const tabsWithKeys: TabWithKey[] = tabs.map(tab => ({
    ...tab,
    textKey: getTabKey(tab.path, activeRole.roleType)
  }))

  return { defaultTab, tabs: tabsWithKeys }
}
