import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RegistrationContext, Student } from './registration'
import { OverridePermitModal } from './override-permit-modal'

export function RegistrationErrorTable(props: { students: Student[]; hasUnknownNames: boolean; dryRun: boolean }) {
  const { t } = useTranslation()
  const studentsWithErrors = props.students.filter(student => student.errors && student.errors?.length)

  if (!studentsWithErrors.length) {
    return null
  }

  const studentErrorElements = studentsWithErrors.map(student => (
    <RegistrationErrors key={student.ssn} student={student} dryRun={props.dryRun} />
  ))

  return (
    <>
      {!props.dryRun && (
        <div className="registration-error-notification show js-registration-error-notification">
          <i className="fa fa-exclamation-triangle" aria-hidden="true" />
          <div className="error-text">{t('registration.registration_error_message')}</div>
        </div>
      )}
      <table className="registration-table registration-errors-table">
        <thead>
          <tr>
            <th className="ssn-col">{t('registration.ssn')}</th>
            <th className="name-col">{t('registration.name')}</th>
            <th>
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
              {t('registration.error')}
            </th>
          </tr>
        </thead>
        <tbody>{studentErrorElements}</tbody>
      </table>
      {props.hasUnknownNames && (
        <div className="info_container">
          <span>*</span>
          <span className="unknown_student_name_info">{t('registration.unknown_name_info_validation_error')}</span>
        </div>
      )}
    </>
  )
}

export function RegistrationWarningTable(props: {
  students: Student[]
  hasUnknownNames: boolean
  showMessage: boolean
}) {
  const { t } = useTranslation()
  const studentsWithWarnings = props.students.filter((student: Student) => student.warnings && student.warnings?.length)

  if (!studentsWithWarnings.length) {
    return null
  }

  const studentWarningElements = studentsWithWarnings.map(student => (
    <RegistrationWarnings key={student.ssn} student={student} />
  ))

  return (
    <>
      {props.showMessage && (
        <div className="registration-warning-notification show js-registration-warning-notification">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          <div className="warning-text">{t('registration.registration_warning_message')}</div>
        </div>
      )}
      <table className="registration-table registration-warnings-table">
        <thead>
          <tr>
            <th className="name-col">{t('registration.name')}</th>
            <th className="ssn-col">{t('registration.ssn')}</th>
            <th>
              <i className="fa fa-exclamation-circle" aria-hidden="true" />
              {t('registration.warning')}
            </th>
          </tr>
        </thead>
        <tbody>{studentWarningElements}</tbody>
      </table>
      {props.hasUnknownNames && (
        <div className="info_container">
          <span>*</span>
          <span className="unknown_student_name_info">{t('registration.unknown_name_info_validation_error')}</span>
        </div>
      )}
    </>
  )
}

function RegistrationErrors(props: { student: Student; dryRun: boolean }) {
  const { t } = useTranslation()
  const { ssn, unknownName, errors, firstnames, lastname, freeEducationOverridePermitExplanation } = props.student
  const [modalOpen, setModalOpen] = useState(false)
  const registrationContext = useContext(RegistrationContext)
  const valpasErrorCodes = [113, 114, 119]

  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = (persistedExplanation?: string) => {
    setModalOpen(false)
    if (persistedExplanation) {
      registrationContext.updateStudent({
        ...props.student,
        freeEducationOverridePermitExplanation: persistedExplanation
      })
    }
  }

  const studentAlreadyHasPermit = !!freeEducationOverridePermitExplanation
  const shouldDisplayOverrideButton = (error: { code: number }) =>
    !props.dryRun && valpasErrorCodes.includes(error.code) && !registrationContext.isAhvenanmaa

  const errorElements = errors.map(error => (
    <div key={error.code}>
      <div className="registration-error">
        <div>{error.msg}</div>
        {shouldDisplayOverrideButton(error) && (
          <div className="override-modal">
            <OverridePermitModal isOpen={modalOpen} student={props.student} handleCloseModal={handleCloseModal} />
            <button className="override-permit-button" onClick={handleOpenModal} disabled={studentAlreadyHasPermit}>
              {t('registration.override.button')}
            </button>
            {studentAlreadyHasPermit && (
              <div className="override-button-sidenote">{t('registration.override.student_already_has')}</div>
            )}
          </div>
        )}
      </div>
      <hr className="separator" />
    </div>
  ))

  return (
    <tr>
      <td className="ssn-col">{ssn}</td>
      <td className={`name-col${unknownName ? ' unknown' : ''}`}>
        {unknownName ? t('registration.unknown_name') : `${lastname}, ${firstnames}`}
      </td>
      <td className="registration-errors">{errorElements}</td>
    </tr>
  )
}

function RegistrationWarnings(props: { student: Student }) {
  const { t } = useTranslation()
  const { ssn, unknownName, warnings, firstnames, lastname } = props.student
  if (!warnings) {
    return null
  }

  const warningElements = warnings.map(warning => (
    <div key={warning.code}>
      <div className="registration-warning">
        {warning.overrideExplanation && (
          <>
            {t('registration.override.explanation_prefix')}: {warning.overrideExplanation}
          </>
        )}
        {!warning.overrideExplanation && warning.msg}
      </div>
      <hr className="separator" />
    </div>
  ))

  return (
    <tr>
      <td className={`name-col${unknownName ? ' unknown' : ''}`}>
        {unknownName ? t('registration.unknown_name') : `${lastname}, ${firstnames}`}
      </td>
      <td className="ssn-col">{ssn}</td>
      <td className="registration-warnings">{warningElements}</td>
    </tr>
  )
}
