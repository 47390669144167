import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatGradingStatus } from '@digabi/grading-ui/lib/held-exams/format-held-exams'
import { getInitialStatus, toggleCollapseTableCookie } from './collapsable-table'
import { GradingProgress } from '@digabi/grading-ui/lib/held-exams/grading-progress'
import { useAxiosGet } from '../hooks'
import { ArpaExamGradingStatus, ExamGradingStatus } from '@digabi/grading-ui/lib/held-exams/types'

type Censor = {
  censorId: string
  shortCode: string
}

type NextLevelResponse = { censoringProgress: number; heldExams: ArpaExamGradingStatus[] }

export const NextLevelCensoring = ({ divisionId }: { divisionId: string }) => {
  const { t } = useTranslation()
  const [censors, setCensors] = useState<Censor[]>([])
  const [get, loading] = useAxiosGet()

  useEffect(() => {
    void (async () => {
      const data = await get<Censor[]>(`/censor/censors-of-division/${divisionId}`)
      if (data) {
        setCensors(data.sort((a, b) => a.shortCode.localeCompare(b.shortCode)))
      }
    })()
  }, [divisionId])

  return (
    <div className="exam-table-shadow exam-table-row next-level-censoring-tables">
      <table className="is_censor held-exams-table">
        <thead>
          <tr className="table-section-header">
            <th colSpan={4}>{t('sa.censor.next_level_censoring')}</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={3} className="loading">
                {t('sa.censor.loading')}
              </td>
            </tr>
          )}
          {!loading && censors.length === 0 && (
            <tr>
              <td colSpan={4} className="next-level-censoring-info no-exams-in-next-level-censoring">
                {t('sa.censor.no_exams_in_next_level_censoring')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {censors.map((censor, index) => (
        <NextLevelCensoringTable key={index} {...censor} divisionId={divisionId} />
      ))}
    </div>
  )
}

const NextLevelCensoringTable = ({ censorId, shortCode, divisionId }: Censor & { divisionId: string }) => {
  const { t } = useTranslation()
  const [examsForCensor, setExamsForCensor] = useState<ExamGradingStatus[] | null>(null)
  const collapsableId = `next-level-censoring-${shortCode}`
  const [collapsed, setCollapsed] = useState(getInitialStatus(collapsableId))
  const [get] = useAxiosGet()
  const toggleCollapsed = (e: React.MouseEvent<HTMLTableRowElement>) => {
    toggleCollapseTableCookie(collapsableId, !collapsed)
    setCollapsed(!collapsed)
  }
  useEffect(() => {
    if (collapsed || examsForCensor) return
    void (async () => {
      const data = await get<NextLevelResponse>(
        `/censor/${censorId}/division/${divisionId}/next-level-censoring-status`
      )
      if (data) {
        setExamsForCensor(formatNextLevelCensoring(data).heldExams)
      }
    })()
  }, [collapsed])
  return (
    <table
      data-collapsable-id={collapsableId}
      className={`next-level-censoring held-exams-table is_censor ${collapsed ? 'is_collapsed' : 'is_expanded'}`}>
      <thead>
        <tr className="table-section-header select-censor" onClick={toggleCollapsed}>
          <th colSpan={7} className="first-level-censor">
            <a className="collapse">
              <i className="fa fa-angle-down for_collapsed"></i>
              <i className="fa fa-angle-up for_expanded"></i>
              <span className="censor-code">{shortCode}</span>
            </a>
          </th>
        </tr>
      </thead>
      <tbody className="for_expanded">
        {!examsForCensor ? (
          <tr>
            <td colSpan={3} className="loading">
              {t('sa.censor.loading')}
            </td>
          </tr>
        ) : (
          <tr className="exam-row js-exam-row-header" data-censor-id={censorId}>
            <th>{t('sa.yo.exam_date')}</th>
            <th>{t('sa.held_exam_name')}</th>
            <th>{t('sa.yo.school_anon_code')}</th>
            <th className="first-censor-progress">{t('sa.held_graded_answers')}</th>
            <th className="header-additional-censor-grading">{t('sa.2nd_censor')}</th>
            <th className="header-additional-censor-grading">{t('sa.3rd_censor')}</th>
            <th></th>
          </tr>
        )}
        {examsForCensor &&
          examsForCensor?.map(
            (
              {
                nextLevelCensoringCompleted,
                eventDateStr,
                title,
                schoolAnonCode,
                censorGradingCompleted,
                censoringProgress,
                secondRoundAnswers,
                secondRoundCensoredAnswers,
                thirdRoundAnswers,
                thirdRoundCensoredAnswers,
                censoringAnswerProgress
              },
              index
            ) => (
              <tr key={index} className={`exam-row ${nextLevelCensoringCompleted ? 'completed' : ''}`}>
                <td>{eventDateStr}</td>
                <td>{title}</td>
                <td>{schoolAnonCode}</td>
                <td className="progress">
                  <GradingProgress gradingCompleted={censorGradingCompleted} gradingProgress={censoringProgress} />
                  <GradingProgress
                    gradingCompleted={censorGradingCompleted}
                    gradingProgress={censoringAnswerProgress}
                  />
                </td>
                <td>{secondRoundAnswers ? `${secondRoundCensoredAnswers || 0}/${secondRoundAnswers}` : ''}</td>
                <td>{thirdRoundAnswers ? `${thirdRoundCensoredAnswers || 0}/${thirdRoundAnswers}` : ''}</td>
                <td>
                  <a className="link-to-grades button" href={`/censor/grading/${schoolAnonCode}`}>
                    {t('sa.held_exam_grade')}
                  </a>
                </td>
              </tr>
            )
          )}
      </tbody>
    </table>
  )
}

const formatNextLevelCensoring = (nextLevelCensoring: NextLevelResponse) => {
  const { censoringProgress, heldExams } = nextLevelCensoring
  const formattedHeldExams = formatGradingStatus(heldExams)
  const sortedExams = formattedHeldExams.sort((a, b) => a.eventDate.localeCompare(b.eventDate))
  return { censoringProgress, heldExams: withNextLevelStatus(sortedExams) }
}

function withNextLevelStatus(exams: ExamGradingStatus[]) {
  return exams.map(exam => ({ ...exam, nextLevelCensoringCompleted: nextLevelCensoringCompleted(exam) }))
}

function nextLevelCensoringCompleted(exam: ExamGradingStatus) {
  if (exam.secondRoundAnswers && exam.secondRoundCensoredAnswers < exam.secondRoundAnswers) {
    return false
  }
  if (exam.thirdRoundAnswers && exam.thirdRoundCensoredAnswers < exam.thirdRoundAnswers) {
    return false
  }
  return true
}
