import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { TabProps } from '../common/types'

type Credentials = {
  schoolId: string
  apiKey: string
}

export function Credentials({ scopeId }: TabProps) {
  const { t } = useTranslation()
  const [apiKey, setApiKey] = useState<string>('')
  const [codeCopied, setCodeCopied] = useState(false)

  useEffect(() => {
    void (async () => {
      const response = await fetch(`/kurko-api/api-key/${scopeId}`)
      if (response.ok) {
        const { apiKey } = (await response.json()) as Credentials
        setApiKey(apiKey)
      }
    })()
  }, [])

  function copyCodeToClipboard() {
    navigator.clipboard.writeText(apiKey).catch(console.error)
    setCodeCopied(true)
    setTimeout(() => setCodeCopied(false), 3000)
  }

  return (
    <div id="credentials-tab" className="tab">
      <h1>{t('credentials.title')}</h1>
      {apiKey === undefined ? (
        <div className="missing">{t('credentials.missing')}</div>
      ) : (
        <div>
          <input type="text" value={apiKey} className="api-key" readOnly={true} size={apiKey.length + 1} />
          &nbsp;
          <button className="copy-api-key-btn" onClick={copyCodeToClipboard}>
            {t('credentials.copy')}
          </button>
          {codeCopied && <span className="copied">{t('credentials.copied')}</span>}
        </div>
      )}
      <div>{t('credentials.instructions')}</div>
    </div>
  )
}
