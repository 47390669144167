import i18n from 'i18next'
import translationFI from '../locales/fi/translation.json'
import translationSV from '../locales/sv/translation.json'
// @ts-expect-error no types
import jqI18next from 'jquery-i18next'
import i18nXhr from 'i18next-xhr-backend'
import i18nLangDetector from 'i18next-browser-languagedetector'
import _ from 'lodash'
import { initReactI18next } from 'react-i18next'
import $ from 'jquery'

const migrateLanguageCookie = () => {
  const cookieParts = document.cookie.split(';').map(c => c.trim())
  const saLangCookie = _.find(cookieParts, c => _.includes(c, 'saLang'))
  if (!saLangCookie) {
    return false
  }

  const languageMapping = { fin: 'fi', swe: 'sv' }
  const lang = saLangCookie.split('=')[1] as 'fin' | 'swe'

  if (!languageMapping[lang]) {
    return false
  }

  const newLang = `saLang=${languageMapping[lang]}`
  document.cookie = newLang
  return true
}

migrateLanguageCookie()

const detectionOptions = {
  order: ['cookie'],
  lookupCookie: 'saLang',
  cookieMinutes: 5 * 365 * 24,
  caches: ['cookie']
}

// type is to check that the translation keys are the same in all languages
export const resources: Record<string, { translation: typeof translationFI }> = {
  fi: { translation: translationFI },
  sv: { translation: translationSV }
}

const fallbackLng = 'fi'
const i18nConfig = {
  useCookie: true,
  detection: detectionOptions,
  fallbackLng,
  returnNull: false,
  resources
}
export function init(callback: () => Promise<void> | void) {
  void i18n
    .use(i18nXhr)
    .use(i18nLangDetector)
    .use(initReactI18next)
    .init(i18nConfig, () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      jqI18next.init(i18n, $, {
        useOptionsAttr: true
      })
      // @ts-expect-error no types
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      $('[data-i18n]').localize()
      void callback()
    })
}
