import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { isSsnValid } from '@digabi/js-utils/dist/validation'
import { useDebounce } from './common/useDebounce'
import { TabProps } from './common/types'
import { LoadingSpinner } from './common/loading-spinner'
import { useAxiosGet } from './hooks'
import { FloatingErrorContext } from './common/floating-error'
import { useTranslation } from 'react-i18next'
import { Language } from '@digabi/grading-ui/lib/common/types'

interface Teacher {
  ssn: string
  name?: string
  disqualifications?: string[]
  allowedExams?: string[]
}

interface Student {
  studentUuid: string
  firstnames: string
  lastname: string
  ssn: string
}

interface Exam {
  examCode: string
  examName: { fi: string; sv: string }
}

interface ManageTeacherContext {
  scopeId: string
  students: Student[]
  exams: Exam[]
  editing: string
  setEditing: (value: string) => void
  modifyTeacher: (value: Teacher) => void
}

const ManageTeacherContext = React.createContext<ManageTeacherContext>({} as ManageTeacherContext)

interface GenericProps<T> {
  id: string
  editing: boolean
  items: string[]
  allItems: T[]
  keyF: (item: T) => string
  valueF: (item: T) => string
  addF: (item: T) => Promise<void>
  removeF: (item: T) => Promise<void>
}

function ModifiableItemList<T>({ id, editing, items, allItems, keyF, valueF, addF, removeF }: GenericProps<T>) {
  const [selectedItem, setSelectedItem] = useState('')
  const [loading, setLoading] = useState(false)
  const setCurrentError = useContext(FloatingErrorContext)
  const deleteItem = (item: T) => {
    setLoading(true)
    removeF(item)
      .catch(() => setCurrentError('sa.errors.upload_error'))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const item = allItems.filter(item => !items.includes(keyF(item))).find(item => valueF(item) === selectedItem)
    if (!item) return

    setLoading(true)
    addF(item)
      .catch(() => setCurrentError('sa.errors.upload_error'))
      .finally(() => {
        setLoading(false)
        setSelectedItem('')
      })
  }, [selectedItem])

  return (
    <div className={`teacher-${id}`}>
      <ul className={`teacher-${id}-list`}>
        {items
          .map(hadItem => allItems.find(item => keyF(item) === hadItem))
          .filter(item => !!item)
          .map(item => (
            <li key={keyF(item!)}>
              <div className={`${id}-list-item`}>
                <div className={`teacher-${id}-value`}>{valueF(item!)}</div>
                {editing && (
                  <a onClick={() => void deleteItem(item!)}>
                    <i className="fa fa-times-circle" />
                  </a>
                )}
              </div>
            </li>
          ))}
      </ul>
      {editing && (
        <>
          <input
            list={`${id}-datalist`}
            disabled={loading}
            value={selectedItem}
            onChange={({ target }) => setSelectedItem(target.value)}
            className={`teacher-${id}-input`}
          />
          <datalist id={`${id}-datalist`}>
            {allItems
              .filter(item => !items.includes(keyF(item)))
              .map(item => (
                <option key={keyF(item)} value={valueF(item)}>
                  {valueF(item)}
                </option>
              ))}
          </datalist>
        </>
      )}
    </div>
  )
}

const optionValue = ({ firstnames, lastname, ssn }: Student) => `${firstnames} ${lastname} ${ssn}`

const Disqualifications = ({ teacher }: { teacher: Teacher }) => {
  const { scopeId, editing, students, modifyTeacher } = useContext(ManageTeacherContext)
  const { i18n } = useTranslation()
  const lang = i18n.language as Language

  const deleteDisqualification = (student: Student) => {
    const disqualifications = teacher.disqualifications?.filter(uuid => uuid !== student.studentUuid)
    return axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/${teacher.ssn}/disqualifications`, { disqualifications })
      .then(() => modifyTeacher({ ...teacher, disqualifications }))
  }

  const addDisqualifications = (student: Student) => {
    const disqualifications = [...(teacher.disqualifications || []), student.studentUuid]
    return axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/${teacher.ssn}/disqualifications`, { disqualifications })
      .then(() => modifyTeacher({ ...teacher, disqualifications }))
  }

  return (
    <ModifiableItemList
      id="disqualifications"
      editing={teacher.ssn === editing}
      items={teacher.disqualifications || []}
      allItems={students.sort((a, b) => optionValue(a).localeCompare(optionValue(b), lang))}
      keyF={e => e.studentUuid}
      valueF={optionValue}
      addF={addDisqualifications}
      removeF={deleteDisqualification}
    />
  )
}

const AllowedExams = ({ teacher }: { teacher: Teacher }) => {
  const { scopeId, exams, editing, modifyTeacher } = useContext(ManageTeacherContext)
  const { i18n } = useTranslation()
  const lang = i18n.language as Language

  const deleteAllowedExam = (exam: Exam) => {
    const allowedExams = teacher.allowedExams?.filter(examCode => examCode !== exam.examCode)
    return axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/${teacher.ssn}/allowedExams`, { allowedExams })
      .then(() => modifyTeacher({ ...teacher, allowedExams }))
  }

  const addAllowedExam = (exam: Exam) => {
    const allowedExams = [...(teacher.allowedExams || []), exam.examCode]
    return axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/${teacher.ssn}/allowedExams`, { allowedExams })
      .then(() => modifyTeacher({ ...teacher, allowedExams }))
  }

  return (
    <ModifiableItemList
      id="allowed-exams"
      editing={teacher.ssn === editing}
      items={teacher.allowedExams || []}
      allItems={exams.sort((a, b) => a.examName[lang].localeCompare(b.examName[lang], lang))}
      keyF={e => e.examCode}
      valueF={e => e.examName[lang]}
      addF={addAllowedExam}
      removeF={deleteAllowedExam}
    />
  )
}

const Teacher = ({ teacher, removeTeacher }: { teacher: Teacher; removeTeacher: (ssn: string) => void }) => {
  const { t } = useTranslation()
  const [name, setName] = useState(teacher.name || '')
  const debouncedName = useDebounce<string>(name)
  const { scopeId, editing, modifyTeacher, setEditing } = useContext(ManageTeacherContext)
  const setCurrentError = useContext(FloatingErrorContext)

  useEffect(() => {
    if (debouncedName === teacher.name) return

    axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/${teacher.ssn}/name`, { name })
      .then(() => modifyTeacher({ ...teacher, name }))
      .catch(() => setCurrentError('sa.errors.upload_error'))
  }, [debouncedName])

  const deleteTeacher = () => {
    axios
      .post(`/kurko-api/user-management/${scopeId}/teachers/unlink`, { ssn: teacher.ssn })
      .then(() => removeTeacher(teacher.ssn))
      .catch(() => setCurrentError('sa.errors.upload_error'))
  }

  const isBeingEdited = editing === teacher.ssn
  return (
    <tr className={isBeingEdited ? 'teacher-under-edit' : 'teacher-row'}>
      <td>
        <div className="teacher-ssn-wrapper">
          <a className="edit-teacher" onClick={() => setEditing(isBeingEdited ? '' : teacher.ssn)}>
            <i className={`fa ${isBeingEdited ? 'fa-check' : 'fa-edit'}`} />
          </a>
          <div>
            <div className="teacher-ssn">{teacher.ssn}</div>
            {isBeingEdited && (
              <a onClick={() => void deleteTeacher()} className="teacher-delete-link">
                {t('sa.yo.teachers.remove')}
              </a>
            )}
          </div>
        </div>
      </td>
      <td>
        {isBeingEdited ? (
          <input
            type="text"
            value={name}
            onChange={({ target }) => setName(target.value)}
            spellCheck={false}
            className="teacher-name"
          />
        ) : (
          <div className="teacher-name">{teacher?.name}</div>
        )}
      </td>
      <td>
        <AllowedExams teacher={teacher} />
      </td>
      <td>
        <Disqualifications teacher={teacher} />
      </td>
    </tr>
  )
}

const TeacherList = ({ teachers, removeTeacher }: { teachers: Teacher[]; removeTeacher: (ssn: string) => void }) => {
  const { t } = useTranslation()
  return (
    <table id="added-teachers">
      <thead>
        <tr>
          <th className="teachers-ssn">{t('sa.yo.teachers.ssn')}</th>
          <th className="teachers-name">{t('sa.yo.teachers.name')}</th>
          <th className="teachers-allowedExams">{t('sa.yo.teachers.allowed_exams')}</th>
          <th className="teachers-disqualification">{t('sa.yo.teachers.disqualification')}</th>
        </tr>
      </thead>
      <tbody>
        {teachers?.map(teacher => <Teacher key={teacher.ssn} {...{ teacher }} removeTeacher={removeTeacher} />)}
      </tbody>
    </table>
  )
}

const validateSsn = (newTeacherSsn: string, teachers: Teacher[]) => {
  if (newTeacherSsn.length === 11 && !isSsnValid(newTeacherSsn)) return 'invalid-ssn'
  if (teachers.some(teacher => teacher.ssn === newTeacherSsn)) return 'duplicate-ssn'
}

const AddTeacherForm = ({
  teachers,
  addTeacher,
  loading
}: {
  teachers: Teacher[]
  addTeacher: (teacher: Teacher) => void
  loading: boolean
}) => {
  const { t, i18n } = useTranslation()
  const [newTeacherName, setNewTeacherName] = useState('')
  const [newTeacherSsn, setNewTeacherSsn] = useState('')
  const [newTeacherExams, setNewTeacherExams] = useState<string[]>([])
  const { scopeId, exams, setEditing } = useContext(ManageTeacherContext)
  const lang = i18n.language as Language
  const setCurrentError = useContext(FloatingErrorContext)

  const newTeacherSsnErrors = validateSsn(newTeacherSsn, teachers)
  const newTeacherSsnValid = newTeacherSsn.length === 11 && !newTeacherSsnErrors

  const submitTeacher = () => {
    if (!newTeacherSsnValid) return

    axios
      .post(`/kurko-api/user-management/${scopeId}/teachers`, {
        ssn: newTeacherSsn,
        name: newTeacherName?.trim(),
        allowedExams: newTeacherExams
      })
      .then(() => {
        addTeacher({ ssn: newTeacherSsn, name: newTeacherName?.trim(), allowedExams: newTeacherExams })
        setEditing(newTeacherSsn)
        setNewTeacherSsn('')
        setNewTeacherName('')
        setNewTeacherExams([])
        return
      })
      .catch(() => setCurrentError('sa.errors.upload_error'))
  }

  return (
    <div className="add-teacher-container">
      <label className="labeled-input">
        <span>{t('sa.yo.teachers.ssn')}</span>
        <input
          type="text"
          disabled={loading}
          onKeyUp={e => e.key === 'Enter' && submitTeacher()}
          onChange={e => setNewTeacherSsn(e.target.value)}
          value={newTeacherSsn}
          maxLength={11}
          spellCheck={false}
          className={`teacher-ssn ${newTeacherSsnErrors ? 'input-error' : ''}`}
        />
        {newTeacherSsnErrors && (
          <div id="add-teacher-validation-error">{t(`sa.yo.teachers.${newTeacherSsnErrors}`)}</div>
        )}
      </label>
      <label className="labeled-input">
        <span>{t('sa.yo.teachers.name')}</span>
        <input
          type="text"
          disabled={loading}
          onKeyUp={e => e.key === 'Enter' && submitTeacher()}
          onChange={e => setNewTeacherName(e.target.value)}
          value={newTeacherName}
          spellCheck={false}
          className="teacher-name"
        />
      </label>
      <label className="labeled-input">
        <span>{t('sa.yo.teachers.allowed_exams')}</span>
        <ModifiableItemList
          id="new-allowed-exams"
          editing={true}
          items={newTeacherExams}
          allItems={exams.sort((a, b) => a.examName[lang].localeCompare(b.examName[lang], lang))}
          keyF={e => e.examCode}
          valueF={e => e.examName[lang]}
          addF={exam => Promise.resolve(setNewTeacherExams(exams => [...exams, exam.examCode]))}
          removeF={exam => Promise.resolve(setNewTeacherExams(exams => exams.filter(e => e !== exam.examCode)))}
        />
      </label>
      <button id="add-teacher" onClick={() => void submitTeacher()} disabled={loading || !newTeacherSsnValid}>
        {t('sa.yo.teachers.add')}
      </button>
    </div>
  )
}

export const Teachers = ({ scopeId }: TabProps) => {
  const { t, i18n } = useTranslation()
  const [editing, setEditing] = useState('')
  const [students, setStudents] = useState<Student[]>([])
  const [exams, setExams] = useState<Exam[]>([])
  const [teachers, setTeachers] = useState<Teacher[]>([])
  const lang = i18n.language as Language
  const [get, loading] = useAxiosGet()

  const addTeacher = (newTeacher: Teacher) => {
    setTeachers(teachers => [...teachers, newTeacher].sort((a, b) => (a.name || '').localeCompare(b.name || '', lang)))
  }

  const removeTeacher = (ssn: string) => setTeachers(teachers => teachers.filter(teacher => teacher.ssn !== ssn))

  const modifyTeacher = (modifiedTeacher: Teacher) => {
    setTeachers(teachers => teachers.map(teacher => (teacher.ssn === modifiedTeacher.ssn ? modifiedTeacher : teacher)))
  }

  useEffect(() => {
    void (async () => {
      const [students, exams, teachers] = await Promise.all([
        get<{ students: Student[] }>(`/registration/${scopeId}/registration-status`),
        get<Exam[]>(`/exam-api/exams/exam-event/all-exams`),
        get<Teacher[]>(`/kurko-api/user-management/${scopeId}/teachers`)
      ])
      if (students) {
        setStudents(students.students)
      }
      if (exams) {
        setExams(exams)
      }
      if (teachers) {
        setTeachers(teachers)
      }
    })()
  }, [scopeId])

  if (loading) {
    return <LoadingSpinner loading={loading} />
  }

  return (
    <ManageTeacherContext.Provider value={{ scopeId, students, exams, editing, modifyTeacher, setEditing }}>
      <div id="exam-teachers-tab" className="tab">
        <p className="teachers-description">{t('sa.yo.teachers.description')}</p>
        <AddTeacherForm teachers={teachers} addTeacher={addTeacher} loading={loading} />
        <TeacherList teachers={teachers} removeTeacher={removeTeacher} />
      </div>
    </ManageTeacherContext.Provider>
  )
}
