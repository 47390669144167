import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

export const LoginPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="login-notification" data-i18n="sa.yo.service-break-20180201-20180206"></div>
      <div id="vetuma-login">
        <h1>{t('sa.yo.login-header')}</h1>
        <p>{t('sa.yo.login-info')}</p>
        <h3>{t('sa.yo.login-method-header')}</h3>
        <ul className="login-method-list">
          <li>{t('sa.yo.login-method-bank')}</li>
          <li>{t('sa.yo.login-method-mobile')}</li>
          <li>{t('sa.yo.login-method-id-card')}</li>
        </ul>
        <div className="login-error">{t('sa.yo.login-error')}</div>
        <button
          className="login"
          onClick={() => window.location.assign(`/kurko-api/user/login/vetuma?lang=${i18next.language}`)}>
          {t('sa.yo.login-button')}
        </button>
      </div>
    </>
  )
}
