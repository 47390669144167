import React from 'react'
import { useTranslation } from 'react-i18next'

interface PaymentInformationProps {
  paymentInfo: {
    referenceCode: string
    examinationPeriodSeasonIsAutumn: boolean | null
    examinationPeriodYear: string
    studentFeeTotalForSchool: string
  }
}

export function PaymentInformation(props: PaymentInformationProps) {
  const { t } = useTranslation()
  const { referenceCode, examinationPeriodSeasonIsAutumn, examinationPeriodYear, studentFeeTotalForSchool } =
    props.paymentInfo
  return (
    <>
      <h3>{t('registration.payment_info')}</h3>

      <table className="js-invoice-table basic-table narrow-table">
        <tbody>
          <tr>
            <th>{t('registration.bank_account_number')}</th>
            <td>
              <dl>
                <dt>Danske Bank</dt>
                <dd>FI51 8129 9710 0112 97</dd>
                <dt>Nordea</dt>
                <dd>FI65 1804 3000 0156 85</dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th>{t('registration.reference_number')}</th>
            <td>{referenceCode}</td>
          </tr>
          <tr>
            <th>{t('registration.due_date')}</th>
            <td>{examinationPeriodSeasonIsAutumn ? `5.9.${examinationPeriodYear}` : `5.3.${examinationPeriodYear}`}</td>
          </tr>
          <tr>
            <th>{t('registration.student_fee_in_total')}</th>
            <td>{studentFeeTotalForSchool}&nbsp;€</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
