import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { client, RegistrationContext, Student } from './registration'
import uri from 'uri-tag'

export function OverridePermitModal(props: {
  isOpen: boolean
  student: Student
  handleCloseModal: (persistedExplanation?: string) => void
}) {
  const { t } = useTranslation()
  const [isSendNoticeButtonDisabled, setIsSendNoticeButtonDisabled] = useState(true)
  const [graduationNoticeFailed, setGraduationNoticeFailed] = useState(false)
  const [explanation, setExplanation] = useState('')

  const registrationContext = useContext(RegistrationContext)

  useEffect(() => {
    areRequiredFieldsFilled()
  }, [explanation])

  const handleConfirm = async () => {
    try {
      await client.post(uri`/student/override-permit/examination/${registrationContext.examinationPeriod}`, {
        ssn: props.student.ssn,
        explanation: explanation
      })
      props.handleCloseModal(explanation)
    } catch {
      setGraduationNoticeFailed(true)
    }
  }

  function areRequiredFieldsFilled() {
    if (!explanation || explanation.trim().length < 1) {
      setIsSendNoticeButtonDisabled(true)
    } else {
      setIsSendNoticeButtonDisabled(false)
    }
  }

  const { student } = props

  return (
    <div className={props.isOpen ? 'display-block override-modal' : 'display-none override-modal'}>
      <div className="modal-overlay" onClick={() => props.handleCloseModal()}></div>
      <div className="modal-main" onClick={e => e.stopPropagation()}>
        <div>
          <h1>
            {student.lastname}, {student.firstnames}
          </h1>
          <span>{student.ssn}</span>
        </div>
        <div>
          <p>{t('registration.override.text1')}</p>
          <p>{t('registration.override.text2')}</p>
        </div>
        <div>
          {t('registration.override.explanation_title')}*
          <textarea
            value={explanation}
            required
            className="modal-textarea"
            onChange={event => setExplanation(event.target.value)}
          />
        </div>
        <div>
          <button
            id="confirm-button"
            className={isSendNoticeButtonDisabled ? 'confirm-button-disabled' : 'confirm-button'}
            onClick={() => void handleConfirm()}
            disabled={isSendNoticeButtonDisabled}>
            {t('registration.override.confirm')}
          </button>
          <button onClick={() => props.handleCloseModal()} className="cancel-button">
            {t('registration.override.cancel')}
          </button>
          {graduationNoticeFailed && (
            <label style={{ color: 'red' }} htmlFor="confirm-button">
              <b>{t('examination_phase.graduation_notice_modal.graduation_notice_failed')}</b>
            </label>
          )}
        </div>
      </div>
    </div>
  )
}
