import React from 'react'
import { useTranslation } from 'react-i18next'

export const NoAccess = () => {
  const { t } = useTranslation()
  return (
    <>
      <h1>{t('sa.yo.no-schools.title')}</h1>
      <p>{t('sa.yo.no-schools.description')}</p>
      <h2>{t('sa.yo.no-schools.what-next')}</h2>
      <h3>{t('sa.yo.no-schools.principal')}</h3>
      <p>{t('sa.yo.no-schools.principal-instruction')}</p>
      <h3>{t('sa.yo.no-schools.teacher')}</h3>
      <p>{t('sa.yo.no-schools.teacher-instruction')}</p>
      <button className="back-to-login" onClick={() => window.location.assign('/kurko-api/user/logout')}>
        {t('sa.yo.no-schools.back-to-login-button')}
      </button>
    </>
  )
}
