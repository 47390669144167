import * as _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { UserDataContext } from '../app'
import { useTranslation } from 'react-i18next'
import { useAxiosGet } from '../hooks'
import { getInitialStatus, toggleCollapseTableCookie } from './collapsable-table'
import { formatGradingStatus } from '@digabi/grading-ui/lib/held-exams/format-held-exams'
import { GradingProgress } from '@digabi/grading-ui/lib/held-exams/grading-progress'
import { ArpaExamGradingStatus, ExamGradingStatus } from '@digabi/grading-ui/lib/held-exams/types'

type FirstLevelResponse = { censoringProgress: number; heldExams: ArpaExamGradingStatus[] }

type FirstLevelExams = {
  censoringProgress: number
  firstLevelExams: {
    heldExams: ExamGradingStatus[]
    censoringProgress: number
    censoringCompleted: boolean
  }[]
}

export const FirstLevelCensoring = ({ divisionId }: { divisionId: string }) => {
  const { t } = useTranslation()
  const [firstLevelCensoring, setFirstLevelCensoring] = useState<FirstLevelExams | null>(null)
  const [get, loading] = useAxiosGet()

  const { user } = useContext(UserDataContext)

  useEffect(() => {
    void (async () => {
      const data = await get<FirstLevelResponse>(`/censor/division/${divisionId}/first-level-exams`)
      if (data) {
        setFirstLevelCensoring(formatFirstLevelCensoring(data))
      }
    })()
  }, [divisionId])

  const { censoringProgress, firstLevelExams } = firstLevelCensoring || {}
  const examsExist = !loading && firstLevelExams
  return (
    <div id="first-level-censoring" className="exam-table-shadow exam-table-row first-level-censoring-tables">
      <table className="is_censor held-exams-table">
        <thead>
          <tr className="table-section-header">
            <th colSpan={9}>
              <span>{t('sa.censor_grading')}</span>: {`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
              {examsExist && firstLevelExams.length > 0 && (
                <span className="total-progress">
                  <span className="separate">&mdash;</span>
                  <span>{t('sa.held_graded')}</span>
                  <GradingProgress gradingProgress={censoringProgress} />
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={3} className="loading">
                {t('sa.censor.loading')}
              </td>
            </tr>
          )}
          {examsExist && firstLevelExams.length === 0 && (
            <tr>
              <td colSpan={3} className="empty">
                {t('sa.censor.no_first_level_students')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {firstLevelExams &&
        firstLevelExams.map(({ heldExams, censoringProgress, censoringCompleted }, index) => (
          <FirstLevelCensoringTable key={index} {...{ heldExams, censoringProgress, censoringCompleted, index }} />
        ))}
    </div>
  )
}

const FirstLevelCensoringTable = ({
  heldExams,
  censoringProgress,
  censoringCompleted
}: {
  heldExams: ExamGradingStatus[]
  censoringProgress: number
  censoringCompleted: boolean
}) => {
  const { t } = useTranslation()
  const collapsableId = `first-level-censoring-${heldExams[0].title}`
  const [collapsed, setCollapsed] = useState(getInitialStatus(collapsableId))

  const toggleCollapsed = (e: React.MouseEvent<HTMLTableRowElement>) => {
    toggleCollapseTableCookie(collapsableId, !collapsed)
    setCollapsed(!collapsed)
  }

  return (
    <table
      className={`is_censor held-exams-table ${collapsed ? 'is_collapsed' : 'is_expanded'}`}
      data-collapsable-id={collapsableId}>
      <thead>
        <tr className="table-section-header select-exam" onClick={toggleCollapsed}>
          <th colSpan={9}>
            <a className="js-exam-title collapse">
              <i className="fa fa-angle-down for_collapsed"></i>
              <i className="fa fa-angle-up for_expanded"></i>
              <div className="exam-title-collapse-link-content">
                <div className="details">{`${heldExams[0].eventDateStr} (${heldExams.length})`}</div>
                <GradingProgress
                  gradingCompleted={censoringCompleted}
                  gradingProgress={censoringProgress}
                  testId="total-progress"
                />
                <div className="name">{heldExams[0].title}</div>
              </div>
            </a>
          </th>
        </tr>
        <tr className="for_expanded">
          <th className="header-index"></th>
          <th className="header-school-exam-anon-code">{t('sa.yo.school_anon_code')}</th>
          <th className="header-students">{t('sa.students')}</th>
          <th className="header-held-answers">{t('sa.held_answers')}</th>
          <th className="header-pre-grading">{t('sa.pre_grading')}</th>
          <th className="header-first-censor-progress first-censor-progress">{t('sa.held_graded_answers')}</th>
          <th className="header-additional-censor-grading">{t('sa.2nd_censor')}</th>
          <th className="header-additional-censor-grading">{t('sa.3rd_censor')}</th>
          <th className="header-link-to-grades"></th>
        </tr>
      </thead>
      <tbody className="for_expanded">
        {heldExams.map((exam, index) => (
          <FirstLevelExam key={index} {...exam} index={index} />
        ))}
      </tbody>
    </table>
  )
}

const FirstLevelExam = ({
  schoolAnonCode,
  nonAutogradedAnswerPapers,
  answers,
  autogradedScores,
  teacherGradingCompleted,
  teacherGradingFinishedProgress,
  censorGradingCompleted,
  censoringProgress,
  secondRoundAnswers,
  secondRoundCensoredAnswers,
  thirdRoundAnswers,
  thirdRoundCensoredAnswers,
  censorDistributionState,
  index,
  censoringAnswerProgress
}: ExamGradingStatus & { index: number }) => {
  const { t } = useTranslation()
  return (
    <tr
      className={`js-exam-school-row ${
        censorDistributionState === 'distribution' ? 'is_grading-active' : 'is_in-pregrading'
      }`}
      data-school-anon-code={schoolAnonCode}>
      <td className="js-index text-muted">{index + 1}</td>
      <td className="js-school-exam-anon-code">{schoolAnonCode}</td>
      <td className="js-students">{nonAutogradedAnswerPapers}</td>
      <td className="js-held-answers">{answers - autogradedScores}</td>
      <td className="js-pre-grading progress">
        <GradingProgress gradingCompleted={teacherGradingCompleted} gradingProgress={teacherGradingFinishedProgress} />
      </td>
      <td className="js-first-censor-progress progress">
        <div className="for_grading-active for_grading-finished">
          <GradingProgress
            gradingCompleted={censorGradingCompleted}
            gradingProgress={censoringProgress}
            testId="censored-students"
          />
          <GradingProgress
            gradingCompleted={censorGradingCompleted}
            gradingProgress={censoringAnswerProgress}
            testId="censored-answers"
          />
        </div>
      </td>
      <td data-testid={'second-round-progress'}>
        {secondRoundAnswers ? `${secondRoundCensoredAnswers || 0}/${secondRoundAnswers}` : ''}
      </td>
      <td data-testid={'third-round-progress'}>
        {thirdRoundAnswers ? `${thirdRoundCensoredAnswers || 0}/${thirdRoundAnswers}` : ''}
      </td>
      <td className="js-link-to-grades">
        <a className="for_grading-active link-to-grades button" href={`/censor/grading/${schoolAnonCode}`}>
          {t('sa.held_exam_grade')}
        </a>
        <a className="for_in-pregrading link-to-grades" href={`/censor/grading/${schoolAnonCode}`}>
          {t('sa.held_exam_censor')}
        </a>
        <a className="for_grading-finished link-to-grades" href={`/censor/grading/${schoolAnonCode}`}>
          {t('sa.held_exam_censor_finished')}
        </a>
      </td>
    </tr>
  )
}

const formatFirstLevelCensoring = (firstLevelCensoring: FirstLevelResponse) => {
  const { censoringProgress, heldExams } = firstLevelCensoring
  const sortedExams = formatGradingStatus(heldExams).sort((a, b) => a.eventDate.localeCompare(b.eventDate))
  window.localStorage.setItem('allExams', JSON.stringify(sortedExams))
  const groupedExams = Object.values(_.groupBy(sortedExams, 'title'))
  const groupedExamsWithProgress = groupedExams.map(exams => {
    const censored = _.sum(exams.map(exam => exam.censoredAnswerPaperCount))
    const autograded = _.sum(exams.map(exam => exam.nonAutogradedAnswerPapers))
    return {
      heldExams: exams,
      censoringProgress: autograded === 0 ? 0 : Math.floor(100 * (censored / autograded)),
      censoringCompleted: censored === autograded
    }
  })

  return { censoringProgress, firstLevelExams: groupedExamsWithProgress }
}
