import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Student } from './registration'
import axios from 'axios'

interface AcceptedRegistrationsProps {
  students: Student[]
  studentFeeTotalForSchool: string
  hasUnknownNames: boolean
  isFin: boolean
  studentTransfer: boolean
  mode: 'registrations' | 'transfer'
}

export const client = axios.create({
  baseURL: '',
  headers: { 'Content-Type': 'application/json' }
})

type StudentToken = { studentUuid: string; token: string | null } | null

export function AcceptedRegistrations(props: AcceptedRegistrationsProps) {
  const { t } = useTranslation()
  const { students, studentFeeTotalForSchool, hasUnknownNames, isFin, studentTransfer, mode } = props
  const [transferToken, setTransferToken] = useState<StudentToken>(null)
  const isRegistration = mode == 'registrations'

  useEffect(() => {
    // close transfer code block when closing whole student transfer
    if (!studentTransfer) setTransferToken(null)
  }, [studentTransfer])

  const studentElements = students.map(
    student =>
      student.studentUuid && (
        <RegisteredStudent
          student={student}
          isFin={isFin}
          key={student.studentUuid}
          studentTransfer={studentTransfer}
          transferToken={transferToken}
          setTransferToken={setTransferToken}
          mode={mode}
        />
      )
  )

  return (
    <>
      <table className="registration-table accepted-registrations-table">
        <thead>
          <tr>
            {isRegistration && (
              <th className="name-col">
                <div className="lastname">{t('registration.lastname')},</div>
                {t('registration.firstname')}
              </th>
            )}
            {isRegistration && (
              <th className="ssn-col">
                <div>{t('registration.ssn')}</div>
              </th>
            )}
            {isRegistration && <th className="student-number-col">{t('registration.student_number')}</th>}
            <th className="exams-col">{t('registration.exams')}</th>
            {isRegistration && <th className="restart-col">{t('registration.restart')}</th>}
            <th className="examination-type-col">{t('registration.examination_type')}</th>
            <th className="study-type-col">{t('registration.study_type')}</th>
            {isRegistration && <th className="oph-id-number-col">{t('registration.oph_id_number')}</th>}
            <th>{t('registration.free_exams_left')}</th>
            <th>
              <div className="student-fee-total">{t('registration.in_total')}</div>
              {isRegistration && <div className="total-amount">{studentFeeTotalForSchool}&nbsp;€</div>}
            </th>
          </tr>
        </thead>
        <tbody>{studentElements}</tbody>
      </table>
      {hasUnknownNames && (
        <div className="info_container">
          <span>*</span>
          <span className="unknown_student_name_info">{t('registration.unknown_name_info_accepted')}</span>
        </div>
      )}
    </>
  )
}

function RegisteredStudent(props: {
  student: Student
  isFin: boolean
  studentTransfer: boolean
  transferToken: StudentToken
  setTransferToken: (transferCode: StudentToken) => void
  mode: 'registrations' | 'transfer'
}) {
  const { t } = useTranslation()
  const { transferToken, setTransferToken, studentTransfer, mode } = props
  const {
    studentUuid,
    isLaw2022Student,
    lastname,
    firstname,
    unknownName,
    ssn,
    studentNumber,
    exams,
    examinationType,
    studyType,
    ophIdNumber,
    totalFee,
    isRestarter,
    freeExamsLeft
  } = props.student
  const [copied, setCopied] = useState(false)
  const isRegistration = mode == 'registrations'
  const isFirstExaminationStudent = examinationType && examinationType == 'registration.examination_type_names.first'

  const examElements = exams.map(exam => (
    <Exam key={exam.regCode} is2022LawStudent={isLaw2022Student} isFin={props.isFin} exam={exam} />
  ))

  const freeExamsLeftColumn =
    freeExamsLeft === undefined ? (
      '-'
    ) : (
      <>
        {freeExamsLeft} {t('registration.pcs')}
      </>
    )

  const copyTransferCodeToClipboard = (token: string) => {
    navigator.clipboard
      .writeText(token)
      .then(() => setCopied(true))
      .catch(err => setCopied(false))
  }

  const fetchOrCreateTransferToken = () => {
    client
      .get<{ transferToken: string }>(`/registration/transfer/get-token/${studentUuid}`)
      .then(res => {
        const token = res.data.transferToken
        copyTransferCodeToClipboard(token)
        return setTransferToken({ studentUuid, token })
      })
      .catch(() => setTransferToken({ studentUuid, token: null }))
  }

  const StudentTransferCode = () => (
    <div className="student-transfer-code">
      <div className="close" onClick={() => setTransferToken(null)} />
      <span className="code">
        {t('registration.transfer.code')}:&nbsp;
        {transferToken?.token ? transferToken.token : t('registration.transfer.failure.search_code')}
      </span>
      {transferToken?.token && (
        <>
          &nbsp;
          {copied && <span>{t('registration.transfer.copied')}</span>}
          <div>{t('registration.transfer.instructions')}</div>
        </>
      )}
    </div>
  )

  return (
    <>
      {!isLaw2022Student && isFirstExaminationStudent && (
        <tr className="pre-2022-student">
          <td colSpan={9}>
            <div className="pre2022student" />
          </td>
        </tr>
      )}
      <tr className={!isLaw2022Student ? 'has-pre2022-student' : ''}>
        {isRegistration && (
          <td className={`name-col${unknownName ? ' unknown' : ''}`}>
            {studentTransfer && (
              <a
                href="#"
                className="show-transfer-token"
                onClick={e => {
                  e.preventDefault()
                  setTransferToken({ studentUuid, token: '...' })
                  fetchOrCreateTransferToken()
                }}>
                {t('registration.transfer.show_code')}
              </a>
            )}
            {unknownName ? (
              t('registration.unknown_name')
            ) : (
              <>
                <div className="lastname">{lastname},</div>
                <div className="firstname">{firstname}</div>
              </>
            )}
          </td>
        )}
        {isRegistration && (
          <td className="ssn-col">
            {transferToken?.studentUuid == studentUuid && <StudentTransferCode />}
            {ssn}
          </td>
        )}
        {isRegistration && <td className="student-number-col exam-cell">{studentNumber}</td>}
        <td>
          <table className="exams-table">
            <tbody>{examElements}</tbody>
          </table>
        </td>
        {isRegistration && <td className="restart-col">{isRestarter ? '✔' : ' '}</td>}
        <td className="examination-type-col">{t(`${examinationType}`)}</td>
        <td className="study-type-col">{t(`${studyType}`)}</td>
        {isRegistration && <td className="oph-id-number-col">{ophIdNumber}</td>}
        <td className="free-exams-left-col">{freeExamsLeftColumn}</td>
        <td className="student-fee-col">{totalFee} €</td>
      </tr>
    </>
  )
}

export function Exam(props: { exam: Student['exams'][0]; is2022LawStudent: boolean; isFin: boolean }) {
  const { t } = useTranslation()
  const { examDetailsNameFinnish, examDetailsNameSwedish, mandatory, registrationInvalidated, free } = props.exam

  const getPre2022StudentExamSuffix = () => {
    if (props.is2022LawStudent) {
      return null
    }

    return mandatory ? <>({t('registration.exam_mandatory')})</> : <>({t('registration.exam_extra')})</>
  }

  const maybeEuroSign = free ? null : <span className="euro-sign" />

  return (
    <tr className={registrationInvalidated ? 'invalidated-registration' : ''}>
      <td className="exams-col exam-cell">
        {props.isFin ? examDetailsNameFinnish : examDetailsNameSwedish} {getPre2022StudentExamSuffix()} {maybeEuroSign}
      </td>
    </tr>
  )
}
