import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { examTypeAbbr } from '../common/resolvers'
import { useAxiosGet } from '../hooks'

type Inspection = {
  examCode: string
  examType: string
  examUuid: string
  inspectionsReady: number
  language: string
  title: string
  totalCount: number
  examTypeAbbr?: string
}

export const Inspections = ({ divisionId }: { divisionId: string }) => {
  const { t } = useTranslation()
  const [inspections, setInspections] = useState<Inspection[]>([])
  const [get, loading] = useAxiosGet()

  useEffect(() => {
    void (async () => {
      const data = await get<Inspection[]>(`/censor/division/${divisionId}/grading-inspections-overview`)
      if (data) {
        setInspections(formatInspections(data))
      }
    })()
  }, [divisionId])
  return (
    loading ||
    (inspections.length > 0 && (
      <div className="exam-table-shadow exam-table-row grading-inspection-wrapper">
        <table className="grading-inspection-tables is_censor held-exams-table">
          <thead>
            <tr className="table-section-header">
              <th colSpan={3}>{t('sa.censor.grading_inspection')}</th>
            </tr>
            <tr className="grading-inspection-headers">
              <th>{t('sa.held_exam_name')}</th>
              <th>{t('sa.censor.in_grading_inspection')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={3} className="loading">
                  {t('sa.censor.loading_grading_inspection')}
                </td>
              </tr>
            )}
            {inspections.map(({ title, inspectionsReady, totalCount, examCode, language, examTypeAbbr }, index) => (
              <tr key={index}>
                <td>{title}</td>
                <td>{`${inspectionsReady} / ${totalCount}`}</td>
                <td>
                  <a
                    href={`/censor/grading/inspection/${examCode}/${language}${examTypeAbbr!}`}
                    className="link-to-inspection button">
                    {t('sa.censor.inspect')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))
  )
}

const formatInspections = (inspections: Inspection[]) =>
  inspections.map(inspection => ({ ...inspection, examTypeAbbr: examTypeAbbr(inspection.examType) }))
